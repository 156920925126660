.logo-strip {
  overflow: hidden; 
  width: 100%;
}

.scrolling-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  animation: scroll 70s linear infinite; 
  white-space: nowrap; 
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 90px; 
}

.logo {
  height: 100px;
  width: auto;
}

@keyframes scroll {
  0% {
      transform: translateX(10%);
  }
  100% {
      transform: translateX(-100%);
  }
}
