:root { 
--gray_50_00:#f7f8fa00; 
--gray_50_01:#f8f9fb; 
--gray_800_06:#574751; 
--red_800:#b93232; 
--gray_800_05:#424242; 
--light_blue_700:#0073e1; 
--gray_800_04:#444444; 
--gray_800_03:#383838; 
--blue_A200:#4285f4; 
--gray_800_02:#404241; 
--blue_gray_600_14:#5b5e8514; 
--gray_800_01:#49494d; 
--blue_A700_66:#2959ff66; 
--black_900_01:#000000; 
--indigo_A700_01:#0011ff; 
--yellow_800:#f89920; 
--gray_800_90:#40424190; 
--teal_900:#042b48; 
--blue_gray_900_02:#141b3e; 
--gray_200_01:#e9e9e9; 
--blue_gray_900_01:#252f3e; 
--blue_gray_900:#292d30; 
--gray_200_00:#e9e9e900; 
--gray_400:#b0afaf; 
--blue_gray_100:#cccccc; 
--orange_A200:#fbad41; 
--blue_A700_19:#2959ff19; 
--amber_500:#fbbc05; 
--gray_800:#404242; 
--orange_800:#f0670a; 
--gray_200:#f3f1ea; 
--black_900_0c:#0000000c; 
--blue_A700_99:#2959ff99; 
--yellow_A700:#ffd00e; 
--blue_A700_ab:#2959ffab; 
--indigo_400:#5460c6; 
--black_900_11:#00000011; 
--indigo_A700:#2f3bda; 
--gray_100_01:#f0f5fc; 
--white_A700:#ffffff; 
--red_700:#c74634; 
--blue_A700:#2959ff; 
--gray_900_cc:#121413cc; 
--blue_gray_100_01:#d9d9d9; 
--red_500:#ea4335; 
--blue_A700_87:#2959ff87; 
--green_600:#34a853; 
--blue_A700_01:#2859ff; 
--gray_50:#f7f8fa; 
--blue_A700_90:#2959ff90; 
--black_900:#0e0e0e; 
--blue_A200_01:#4f88f7; 
--yellow_700:#ffbe22; 
--yellow_900:#f6821f; 
--black_900_28:#00000028; 
--blue_A700_7e:#2959ff7e; 
--deep_orange_A400:#ff4a00; 
--gray_900_02:#222222; 
--gray_700:#656565; 
--indigo_400_01:#5560c6; 
--blue_gray_400:#8c8c8c; 
--white_A700_a2:#ffffffa2; 
--gray_900:#121413; 
--gray_900_01:#141414; 
--orange_500:#ff9900; 
--gray_300:#e4e4e4; 
--blue_gray_100_19:#cfcfcf19; 
--gray_100:#f4f4f4; 
--gray_50_e5:#f7f8fae5; 
--white_A700_00:#ffffff00; 
--yellow_900_01:#f58127; 
--indigo_100:#b7cde5; 
--blue_A700_6c:#2959ff6c; 
--gray_900_90:#12141390; 
--blue_A700_a2:#2959ffa2; 
}